<!--
Display all maps found in ScrapFNMaps
Please display each object using MapCard.vue component
New:
Add a search bar to filter the maps by name, author, code and tags
-->

<template>
  <div>
    <p>Number of Maps: {{ maps.length }}</p>
    <b-form @submit.prevent>
      <b-form-group label="Search">
        <b-input v-model="searchTerm" />
      </b-form-group>
    </b-form>
    <b-row>
      <b-col md="4" v-for="(map, index) in filteredMaps" :key="index">
        <MapCard :map="map" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { db } from '@/services/db'

export default {
  name: 'Maps',
  components: {
    MapCard : () => import('./components/MapCard.vue')
  },
  data() {
    return {
      maps: [],
      searchTerm: ''
    }
  },
  computed: {
    filteredMaps() {
      return this.maps.filter(map => {
        return map.name?.toLowerCase().includes(this.searchTerm.toLowerCase())
          || map.author?.toLowerCase().includes(this.searchTerm.toLowerCase())
          || map.islandCode?.toLowerCase().includes(this.searchTerm.toLowerCase())
          || map.tags?.some(tag => tag.toLowerCase().includes(this.searchTerm.toLowerCase()))
      });
    }
  },
  mounted() {
    this.$bind('maps', db.collection('ScrapFNMaps'));
  }
}
</script>
